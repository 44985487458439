@import '../../styles/_mixins.scss';

.menuToggle {
	width: 40px;
	display: flex;
	flex-flow: column;
	justify-content: space-around;
	align-items: center;
	padding: 10px 0;
	box-sizing: border-box;
	cursor: pointer;
}

.menuToggle div {
	width: 90%;
	height: 3px;
	background-color: #abc555;
}


@media (min-width: 768px) {
	.menuToggle {
		display: none;
}
}
