$devices-width:  (
  'lg': 1600px,
  'md': 1024px,
  'sm': 768px
);
$navbar-height: 60px;
$navbar-height-mobile: 70px;
$footer-height: 41px;
$font-size-base: 16px;
$font-size-large: 18px;
$font-size-mobile: 15px;
$font-size-xl: 28px;
$font-size-xl-mobile: 25px;
$widget-box-shadow: 0px 0px 25px;
$widget-border-radius: 8px;
$page-padding: 8px;
$overflowed-elements-z-index: 5;
$portal-z-index: 99;
$section-padding-default: 147px;
$section-padding-mobile: 35px;

$main-section-background-color:white;

// COLORS
$color-primary: #abc555;
$color-primary-light: #abc555;
$color-primary-dark: #202510;

$color-secondary-light: #ffb900;
$color-secondary-dark: #ff7730;

$color-tertiary-light: #2998ff;
$color-tertiary-dark: #2b331078;

$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eee;

$color-grey-dark: #777;
$color-grey-dark-2: #999;
$color-grey-dark-3: #333;

$color-white: #fff;
$color-black: #000;
