@import '../../../styles/mixins';

.menu {
			display: flex;
			flex-flow: row;
			padding: 0;
			margin: 0;
			justify-content: space-around;
			max-width: 1024px;
			height: 100%;
			@include mobile {
				flex-flow: column;
				color: black;
			}
		}

.item {
	display: flex;
  justify-content: center;
	@include mobile {
		width: 100%;
		padding-left: 20px;
	}
}

.item:hover {
  border-bottom: 8px solid #2b3310;
  border-top: 1px solid #2b3310;;
  border-radius: 50%;
  box-shadow: 0px 10px 12px 2px rgba(75,92,78,1);
  background-color: #fffff6;
}

.link {
	display: flex;
	align-items: center;
	justify-content: center;
	color: #2b3310;
	background-color: transparent;
	transition: color 0.2s linear;
		@include mobile {
			height: 50px;
			line-height: 50px;
			color: #2b3310;
		}
}

	.divider {
		padding:  0 20px;
		@include mobile {
			opacity: 0;
		}
	}

	.ancor {
		width: 100%;
		height: 100%;
    display: flex;
    margin: auto;
    align-items: center;
    & span {
      font-size: 22px;
      color:#2b3310;
    }
	}