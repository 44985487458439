
.sideDrawer {
	position: fixed;
	width: 350px;
	max-width: 70%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: 200;
	background-color: #feffed;
	padding: 32px 16px;
	box-sizing: border-box;
	transition: transform 0.4s ease-out;
}

.wrapper {
	position: relative;
	width: 100%;
}

@media (min-width: 767px) {
	.sideDrawer {
		display: none;
	}
}

.open {
	transform: translateX(0);
}

.close {
	transform: translateX(-100%);

}

.logo {
  display: flex;
  margin-right: 50px;
  margin-bottom: 70px;
  font-size: 26px;
  width: 70px;
  height: 70px;
  background-image: url('../../static//images/logo.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  
}